.container {
  position: relative;
}

.showButton {
  padding: rem(4) rem(4) rem(4) 0;
}

.showButtonIcon {
  color: clr(g-25);
  width: rem(16);
  height: rem(20);
}

.viafouraShareBar {
  width: auto;
  border: bdr(1, b/5);
  transform: scale(0.55) translateY(rem(-2));
  background-color: clr(w);
  padding: rem(4);
  top: 100%;
  transition: trn(opacity, transform);
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transform-origin: 0 0;
  left: 0;

  @media bp(sm) {
    transform: scale(0.55) translateY(rem(-2)) translateX(-50%);
  }

  :global(.vf-share-bar-square) {
    width: auto !important;
  }
}

.viafouraShareBarVisible {
  transform: scale(0.6) translateY(0);
  opacity: 1;
  pointer-events: auto;

  @media bp(sm) {
    transform: scale(0.6) translateY(0) translateX(-50%);
  }
}
