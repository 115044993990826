.container {
  gap: rem(20);
}

.tagList {
  gap: rem(5);
  display: flex;
  flex-wrap: wrap;
}

.tag {
  padding: rem(5) 0;
  font-size: rem(12);
  line-height: rem(14);
  color: clr(g-40);

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child)::after {
    content: ',';
  }
}
