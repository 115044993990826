.shortLine {
  margin: rem(12) 0;
}

.splitter {
  display: none;

  @media bp(lg) {
    display: block;
  }
}

.content {
  @media bp(lg) {
    margin-top: rem(20);
  }
}

