.comments {
  margin-top: rem(15);
}

.related {
  margin-top: rem(30);

  /* min-height: rem(142); */

  @media bp(sm) {
    contain: none !important;
    overflow: visible !important;
    margin-bottom: rem(30);
    padding-bottom: rem(30);
  }
}

.breadCrumbs {
  margin-bottom: rem(10);

  @media bp(lg) {
    margin-bottom: rem(15);
  }
}

.followLine {
  margin: rem(20) 0;
}

.postTags {
  margin-top: rem(30);

  @media bp(sm) {
    margin-top: rem(40);
  }
}

.sidebarPostNav {
  position: sticky;
  top: rem(120);

  @media bp(xl) {
    top: rem(140);
  }
}
