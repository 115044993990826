.container {
  display: flex;
  align-items: center;
  gap: rem(16);
  flex-wrap: wrap;
}

.title {
  color: clr(g-25);
  margin-right: rem(8);
  font-size: rem(16);
  font-weight: 600;
  line-height: rem(24);
  letter-spacing: rem(-0.3);
}

.buttons {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: rem(8);
}
