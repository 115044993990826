.pageTitle {
  font-size: rem(32);
  line-height: rem(38);
  font-weight: 600;
  letter-spacing: rem(-0.1);

  @media bp(lg) {
    font-size: rem(40);
    line-height: rem(46);
  }
}

.pageSubTitle {
  font-size: rem(16);
  word-break: break-word;
  line-height: rem(24);
  font-weight: 500;
  letter-spacing: rem(-0.1);
  color: clr(g-25);

  @media bp(lg) {
    font-size: rem(20);
    line-height: rem(26);
    letter-spacing: rem(-0.5);
  }
}
