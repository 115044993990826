.container {
  top: var(--top);
  z-index: 8;
  transition: trn(transform);
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: rem(20);
  transform: translateY(var(--translate-complex));

  --top: rem(50);
  --translate: rem(0);
  --translate2: rem(0);
  --translate-complex: calc(var(--translate) + var(--translate2));

  &.showStickyDealbar {
    --translate2: rem(30);

    @media bp(xl) {
      --translate2: 0;
    }
  }

  &.dealbarSticky:not(.dealbarHiddenOnMobile) {
    --translate: rem(0);
  }

  &:not(.headerVisible) {
    --translate: rem(30);
  }

  &.headerVisible {
    --translate: rem(-10);
  }

  &:not(.hasSections) {
    --translate: rem(0);
  }

  &.previewMode {
    --top: rem(82);
  }

  @media bp(lg) {
    --top: rem(70);

    &.previewMode {
      --top: rem(132);
    }

    &.hasAffilateBar:not(.headerVisible) {
      --translate: rem(30) !important;
    }
  }

  @media bp(xl) {
    &.headerVisible {
      --translate: rem(30);
    }
  }
}
