.container {
  contain: content;
  display: grid;
  row-gap: rem(15);

  @media bp(md) {
    row-gap: rem(20);
  }
}

.image {
  background-color: transparent;
}
