.button {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &:hover {
    .hint {
      opacity: 1;
      pointer-events: all;
    }
  }

  &:not(:last-of-type)::after {
    margin-right: rem(5);
    content: ',';
  }
}

.hint {
  top: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100vw;

  @mixin transition opacity;

  @media bp(sm) {
    width: rem(330);
  }
}

.button:focus-within ~ .fadeLayer {
  opacity: 1;
  pointer-events: all;
}

.fadeLayer {
  opacity: 0;
  pointer-events: none;
  background-color: clr(b/10);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  @mixin transition opacity;

  @media bp(sm) {
    display: none;
  }
}
