.container {
  @media bp(lg) {
    display: grid;
    gap: rem(30) rem(100);
    grid-template-columns: minmax(0, 1fr) rem(300);
  }
}

.sidebar {
  display: none;

  @media bp(lg) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: rem(20);
  }

  /* variables */
  --container-width: 0;

  @media bp(lg) {
    --container-width: rem(300);
  }
}

.content {
  /* variables */
  --container-width: var(--wrapper-inner-width);

  @media bp(lg) {
    --container-width: calc(var(--wrapper-inner-width) - rem(400));
  }
}
