.container {
  display: grid;
  gap: rem(3);

  @media bp(lg) {
    gap: rem(10);
  }
}

.title {
  font-size: rem(20);
  line-height: rem(28);
  font-weight: bold;

  @media bp(lg) {
    font-size: rem(24);
    line-height: rem(32);
  }
}

.wavy {
  width: rem(61);
  height: rem(8);
  stroke: clr(p-70);
  fill: transparent;
  margin: rem(5) 0;

  @media bp(lg) {
    margin: 0;
  }
}

.navLink {
  font-size: rem(18);
  line-height: rem(28);
  font-weight: 500;
  overflow-wrap: anywhere;

  @media bp(lg) {
    font-size: rem(22);
    font-weight: 400;
  }

  &:not(.active) {
    opacity: 0.6;
    transition: trn(opacity);
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    display: flex;
    align-items: center;
    column-gap: rem(10);
  }

  @media bp(xl) {
    &.active {
      display: block;
      position: relative;
    }
  }
}

.iconArrow {
  width: rem(21);
  height: rem(14);
  color: clr(p-70);

  @media bp(xl) {
    position: absolute;
    left: rem(-28);
    top: rem(5);
  }
}
