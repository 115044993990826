.container {
  width: 100%;

  @media bp(lg) {
    max-width: rem(viewport(lg));
    margin-left: auto;
    margin-right: auto;
  }

  /* variables */
  --container-width: var(--wrapper-inner-width);

  @media bp(lg) {
    --container-width: rem(viewport(lg));
  }
}
