.container {
  display: flex;
  column-gap: rem(6);
  align-items: center;
  flex-wrap: wrap;
}

.link {
  font-size: rem(12);
  line-height: rem(16);
  letter-spacing: rem(-0.1);
  text-transform: uppercase;
  font-weight: 600;
  padding: rem(5) 0;
  color: clr(p-40);
}

.lastLink {
  color: clr(g-50);
}

.arrow {
  width: rem(16);
  height: rem(16);
  color: clr(p-40);
}
