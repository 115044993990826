.container {
  @media bp(lg) {
    display: grid;
    column-gap: rem(30);
    grid-template-columns: 1fr rem(300);
  }

  @media bp(xl) {
    display: grid;
    grid-template-columns: minmax(0, 270fr) minmax(0, 660fr) rem(300);
  }
}

.left,
.sidebar {
  display: none;
}

.sidebar {
  @media bp(lg) {
    display: block;
  }

  /* variables */
  --container-width: 0;

  @media bp(lg) {
    --container-width: rem(300);
  }
}

.left {
  @media bp(xl) {
    display: block;
  }

  /* variables */
  --container-width: 0;

  @media bp(xl) {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - rem(330)) / 960) * 270);
  }
}

.content {
  /* variables */
  --container-width: var(--wrapper-inner-width);

  @media bp(lg) {
    --container-width: calc(var(--wrapper-inner-width) - rem(330));
  }

  @media bp(xl) {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - rem(330)) / 960) * 660);
  }
}
