.container {
  display: flex;
  row-gap: rem(40);
  position: relative;
  flex-direction: column;

  .floatingBox {
    height: 100vh;
  }

  .floatingAd {
    position: sticky;
    top: rem(64);
  }
}
