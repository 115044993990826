.e_kb {
  contain: content;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  background-color: rgb(var(--g-90));
}

.e_lb {
  min-height: 7.5rem;
}

.e_mb {
  min-height: 15.625rem;
}

@media (min-width: 64rem) {

.e_lb {
    min-height: 15.625rem
}
}

.e_hc {
  width: 100%;
}

  .e_hc {
  --container-width: var(--wrapper-inner-width);
}

  /* variables */

  @media (min-width: 64rem) {.e_hc {
    max-width: 51.25rem;
    margin-left: auto;
    margin-right: auto
}.e_hc {
    --container-width: 51.25rem
}
}

.e_t {
  margin-top: 1.25rem;
}

.e_u {
  margin: 1.25rem 0;
}

.e_Cn {
  display: flex;
  row-gap: 2.5rem;
  position: relative;
  flex-direction: column;
}

  .e_Cn .e_Dn {
    height: 100vh;
  }

  .e_Cn .e_En {
    position: sticky;
    top: 4rem;
  }

.e_Dc {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.e_9c {
  top: var(--top);
  z-index: 8;
  transition: transform 0.2s;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  transform: translateY(var(--translate-complex));

  --top: 3.125rem;
  --translate: 0rem;
  --translate2: 0rem;
  --translate-complex: calc(var(--translate) + var(--translate2));
}

  .e_9c.e_-c {
    --translate2: 1.875rem;
  }

  .e_9c.e_ad:not(.e_bd) {
    --translate: 0rem;
  }

  .e_9c:not(.e_cd) {
    --translate: 1.875rem;
  }

  .e_9c.e_cd {
    --translate: -0.625rem;
  }

  .e_9c:not(.e_dd) {
    --translate: 0rem;
  }

  .e_9c.e_ed {
    --top: 5.125rem;
  }

  @media (min-width: 64rem) {.e_9c {
    --top: 4.375rem
}

    .e_9c.e_ed {
      --top: 8.25rem;
    }

    .e_9c.e_fd:not(.e_cd) {
      --translate: 1.875rem !important;
    }
}

  @media (min-width: 80rem) {

  .e_9c.e_-c {
      --translate2: 0
  }
    .e_9c.e_cd {
      --translate: 1.875rem;
    }
}

.e_ho,
.e_io {
  display: none;
}

.e_io {
  --container-width: 0;
}

/* variables */

.e_ho {
  --container-width: 0;
}

/* variables */

.e_jo {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_ko {
    display: grid;
    grid-column-gap: 1.875rem;
    -moz-column-gap: 1.875rem;
         column-gap: 1.875rem;
    grid-template-columns: 1fr 18.75rem
}

.e_io {
    display: block
}

.e_io {
    --container-width: 18.75rem
}

.e_jo {
    --container-width: calc(var(--wrapper-inner-width) - 20.625rem)
}
}

@media (min-width: 80rem) {.e_ko {
    display: grid;
    grid-template-columns: minmax(0, 270fr) minmax(0, 660fr) 18.75rem
}

.e_ho {
    display: block
}

.e_ho {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 270)
}

.e_jo {
    --container-width: calc(calc(calc(var(--wrapper-inner-width) - 20.625rem) / 960) * 660)
}
}

.e_le {
  display: none;
}

.e_le {
  --container-width: 0;
}

/* variables */

.e_me {
  /* variables */
  --container-width: var(--wrapper-inner-width);
}

@media (min-width: 64rem) {.e_ne {
    display: grid;
    grid-gap: 1.875rem 6.25rem;
    gap: 1.875rem 6.25rem;
    grid-template-columns: minmax(0, 1fr) 18.75rem
}

.e_le {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    row-gap: 1.25rem
}

.e_le {
    --container-width: 18.75rem
}

.e_me {
    --container-width: calc(var(--wrapper-inner-width) - 25rem)
}
}

.e_5c {
  display: flex;
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_6c {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.00625rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.3125rem 0;
  color: rgb(var(--p-40));
}

.e_7c {
  color: rgb(var(--g-50));
}

.e_8c {
  width: 1rem;
  height: 1rem;
  color: rgb(var(--p-40));
}

.e_qd {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.e_rd {
  color: rgb(var(--g-25));
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.01875rem;
}

.e_sd {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.e_gd {
  min-height: 27.5rem;
}

.e_Qo {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

  .e_Qo:hover .e_Ro {
      opacity: 1;
      pointer-events: all;
    }

  .e_Qo:not(:last-of-type)::after {
    margin-right: 0.3125rem;
    content: ',';
  }

.e_Ro {
  top: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100vw;

  transition: opacity 0.2s;
}

.e_Qo:focus-within ~ .e_So {
  opacity: 1;
  pointer-events: all;
}

.e_So {
  opacity: 0;
  pointer-events: none;
  background-color: rgba(var(--b), 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;

  transition: opacity 0.2s;
}

@media (min-width: 40rem) {

.e_Ro {
    width: 20.625rem
}

.e_So {
    display: none
}
}

.e_Lo {
  position: relative;
}

.e_Mo {
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.e_No {
  color: rgb(var(--g-25));
  width: 1rem;
  height: 1.25rem;
}

.e_Oo {
  width: auto;
  border: 0.0625rem solid rgba(var(--b), 0.05);
  transform: scale(0.55) translateY(-0.125rem);
  background-color: rgb(var(--w));
  padding: 0.25rem;
  top: 100%;
  transition: opacity 0.2s, transform 0.2s;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transform-origin: 0 0;
  left: 0;
}

.e_Oo .vf-share-bar-square {
    width: auto !important;
  }

.e_Po {
  transform: scale(0.6) translateY(0);
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 40rem) {

.e_Oo {
    transform: scale(0.55) translateY(-0.125rem) translateX(-50%)
}

.e_Po {
    transform: scale(0.6) translateY(0) translateX(-50%)
}
}

.e_td {
  display: flex;
  row-gap: 0.25rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  flex-direction: column;
}

.e_ud {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.e_vd {
  color: rgb(var(--p-40));
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
}

.e_wd {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-25));
  letter-spacing: -0.00625rem;
}

.e_xd {
  font-weight: 500;
  font-size: 0.875rem;
  color: rgb(var(--g-65));
  letter-spacing: -0.00625rem;
}

.e_yd {
  display: none;
}

.e_zd {
  height: 1.25rem;
}

.e_Ad {
  color: rgb(var(--g-25));
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.e_Bd {
  padding-left: 0.625rem;
}

.e_Cd {
  display: none;
}

@media (min-width: 40rem) {.e_td {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center
}

.e_Cd {
    display: block
}
}

@media (min-width: 64rem) {

.e_Bd {
    padding-left: 1.875rem
}
}

.e_hd {
  gap: 1.25rem;
}

.e_id {
  gap: 0.3125rem;
  display: flex;
  flex-wrap: wrap;
}

.e_jd {
  padding: 0.3125rem 0;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgb(var(--g-40));
}

.e_jd:hover {
    text-decoration: underline;
  }

.e_jd:not(:last-child)::after {
    content: ',';
  }

.e_ld {
  font-size: 2rem;
  line-height: 2.375rem;
  font-weight: 600;
  letter-spacing: -0.00625rem;
}

.e_md {
  font-size: 1rem;
  word-break: break-word;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.00625rem;
  color: rgb(var(--g-25));
}

@media (min-width: 64rem) {.e_ld {
    font-size: 2.5rem;
    line-height: 2.875rem
}

.e_md {
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: -0.03125rem
}
}

.e_kd {
  margin-top: 0.5rem;
}

.e_Jd {
  display: grid;
  grid-gap: 0.1875rem;
  gap: 0.1875rem;
}

.e_Kd {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
}

.e_Ld {
  width: 3.8125rem;
  height: 0.5rem;
  stroke: rgb(var(--p-70));
  fill: transparent;
  margin: 0.3125rem 0;
}

.e_Md {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  overflow-wrap: anywhere;
}

.e_Md:not(.e_Nd) {
    opacity: 0.6;
    transition: opacity 0.2s;
  }

.e_Md:hover {
    opacity: 1;
  }

.e_Md.e_Nd {
    display: flex;
    align-items: center;
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
  }

.e_Od {
  width: 1.3125rem;
  height: 0.875rem;
  color: rgb(var(--p-70));
}

@media (min-width: 64rem) {.e_Jd {
    gap: 0.625rem
}

.e_Kd {
    font-size: 1.5rem;
    line-height: 2rem
}

.e_Ld {
    margin: 0
}

.e_Md {
    font-size: 1.375rem;
    font-weight: 400
}
}

@media (min-width: 80rem) {
    .e_Md.e_Nd {
      display: block;
      position: relative;
    }

.e_Od {
    position: absolute;
    left: -1.75rem;
    top: 0.3125rem
}
}

.e_v {
  margin-top: 0.9375rem;
}

.e_w {
  margin-top: 1.875rem;
}

/* min-height: rem(142); */

.e_x {
  margin-bottom: 0.625rem;
}

.e_y {
  margin: 1.25rem 0;
}

.e_z {
  margin-top: 1.875rem;
}

.e_A {
  position: sticky;
  top: 7.5rem;
}

@media (min-width: 40rem) {

.e_w {
    contain: none !important;
    overflow: visible !important;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem
}

.e_z {
    margin-top: 2.5rem
}
}

@media (min-width: 64rem) {

.e_x {
    margin-bottom: 0.9375rem
}
}

@media (min-width: 80rem) {

.e_A {
    top: 8.75rem
}
}

.e_B {
  margin: 0.75rem 0;
}

.e_C {
  display: none;
}

@media (min-width: 64rem) {

.e_C {
    display: block
}

.e_D {
    margin-top: 1.25rem
}
}


.e_3d {
  contain: content;
  display: grid;
  grid-row-gap: 0.9375rem;
  row-gap: 0.9375rem;
}

.e_4d {
  background-color: transparent;
}

@media (min-width: 48rem) {.e_3d {
    row-gap: 1.25rem
}
}

.e_S {
  margin: 0.9375rem 0 0.625rem 0;
}@media (min-width: 48rem) {.e_S {
    margin-top: 1.5625rem
}
}

