.container {
  contain: content;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80%;
  background-color: clr(g-90);
}

.leaderboard {
  min-height: rem(120);

  @media bp(lg) {
    min-height: rem(250);
  }
}

.rect {
  min-height: rem(250);
}
