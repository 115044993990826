.container {
  display: flex;
  row-gap: rem(4);
  column-gap: rem(8);
  flex-direction: column;

  @media bp(sm) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

.postedByContainer {
  display: flex;
  gap: rem(8);
  align-items: center;
  flex-wrap: wrap;
}

.postedBy {
  color: clr(p-40);
  font-size: rem(14);
  font-weight: 500;
  letter-spacing: rem(-0.1);
}

.date {
  font-weight: 500;
  font-size: rem(14);
  color: clr(g-25);
  letter-spacing: rem(-0.1);
}

.dateDot {
  font-weight: 500;
  font-size: rem(14);
  color: clr(g-65);
  letter-spacing: rem(-0.1);
}

.datePrefix {
  display: none;
}

.commentsCounterButton {
  height: rem(20);
}

.commentsCounter {
  color: clr(g-25);
  font-size: rem(14);
  line-height: rem(20);
}

.socials {
  padding-left: rem(10);

  @media bp(lg) {
    padding-left: rem(30);
  }
}

.shareDot {
  display: none;

  @media bp(sm) {
    display: block;
  }
}
